import { gql } from "@apollo/client"

export const FRAGMENT_CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    id
    email
    firstName
    lastName
    phoneNumber
    birthDate
    defaultAddress {
      streetOne
      streetTwo
      city
      state
      zip
      country
    }
  }
`

const FRAGMENT_RITUAL_PLUS_FIELDS = gql`
  fragment CoreRitualPlusFields on Subscription {
    active
    lineItems {
      frequencyInWeeks
      product {
        description
        name
        priceSubscription
        sku
        thumbnailUrl
      }
      quantity
      renewsOn
    }
  }
`

export const GET_ME = gql`
  ${FRAGMENT_CORE_USER_FIELDS}
  query GetMe {
    me {
      ...CoreUserFields
    }
  }
`

export const GET_MY_USER_RECORD = gql`
  ${FRAGMENT_CORE_USER_FIELDS}
  ${FRAGMENT_RITUAL_PLUS_FIELDS}
  query GetMyUserRecord {
    me {
      ...CoreUserFields
    }
    cardOnFile {
      cardType
      exp
      lastFour
    }
    myOrderHistory {
      orders {
        createdAt
        id
        lineItems {
          quantity
        }
        paidWith
      }
    }
    myRitualPlus {
      ...CoreRitualPlusFields
    }
  }
`

export const REACTIVATE_RITUAL_PLUS = gql`
  ${FRAGMENT_RITUAL_PLUS_FIELDS}
  mutation ReactivateRitualPlus {
    reactivateSubscription {
      ...CoreRitualPlusFields
    }
  }
`

export const REMOVE_CARD_ON_FILE = gql`
  mutation RemoveCardOnFile {
    removeCardOnFile
  }
`

export const REMOVE_FROM_RITUAL_PLUS = gql`
  ${FRAGMENT_RITUAL_PLUS_FIELDS}
  mutation RemoveFromRitualPlus($input: RitualPlusRemoveInput!) {
    removeFromRitualPlus(input: $input) {
      ...CoreRitualPlusFields
    }
  }
`

export const MAGIC_LINK_REQUEST = gql`
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input)
  }
`

export const SKIP_RITUAL_PLUS_DELIVERY = gql`
  ${FRAGMENT_RITUAL_PLUS_FIELDS}
  mutation SkipRitualPlusDelivery($input: RitualPlusSkipDeliveryInput!) {
    skipRitualPlusDelivery(input: $input) {
      ...CoreRitualPlusFields
    }
  }
`

export const UPDATE_CARD_ON_FILE = gql`
  mutation UpdateCardOnFile($nonce: String!) {
    updateCardOnFile(nonce: $nonce) {
      cardType
      exp
      lastFour
    }
  }
`

export const UPDATE_FREQUENCY_IN_RITUAL_PLUS = gql`
  ${FRAGMENT_RITUAL_PLUS_FIELDS}
  mutation UpdateRitualPlusFrequency($input: RitualPlusUpdateFrequencyInput!) {
    updateRitualPlusFrequency(input: $input) {
      ...CoreRitualPlusFields
    }
  }
`

export const UPDATE_MY_BIRTHDATE = gql`
  mutation UpdateMyBirthdate($date: String) {
    setBirthDate(date: $date)
  }
`

export const UPDATE_MY_PROFILE = gql`
  ${FRAGMENT_CORE_USER_FIELDS}
  mutation UpdateMyProfile($input: UserUpdateProfileInput!) {
    updateMe(input: $input) {
      ...CoreUserFields
    }
  }
`

export const UPDATE_QUANTITY_IN_RITUAL_PLUS = gql`
  ${FRAGMENT_RITUAL_PLUS_FIELDS}
  mutation UpdateRitualPlusQuantity($input: RitualPlusUpdateQuantityInput!) {
    updateRitualPlusQuantity(input: $input) {
      ...CoreRitualPlusFields
    }
  }
`

// export const USER_START_SESSION = gql`
//   query UserStartSession($token: ID) {
//     startSession(token: $token) {
//       cartCount
//       cartId
//       token
//     }
//   }
// `
