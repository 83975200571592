import { initializeApp, getApps } from "firebase/app"
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
} from "firebase/auth"

import type { ReactNode } from "react"

interface FirebaseWrapperProps {
  children: ReactNode
}
export default function firebaseWrapper({ children }: FirebaseWrapperProps) {
  if (getApps().length !== 1) {
    initializeApp({
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    })
    const auth = getAuth()
    setPersistence(auth, browserLocalPersistence)

    if (process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_URL) {
      connectAuthEmulator(
        auth,
        process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_URL
      )
    }
  }

  return <>{children}</>
}
