import { gql } from "@apollo/client"

import { FRAGMENT_CORE_USER_FIELDS } from "@src/operations/api/user"

export const GET_INITIAL_STORE = gql`
  query SetUpStore {
    cartCount
    me {
      ...CoreUserFields
    }
  }
  ${FRAGMENT_CORE_USER_FIELDS}
`

export default {}
