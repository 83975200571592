import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit"

import { getAuth, signOut } from "firebase/auth"

import type { UserState } from "@src/entities/types/state"

import type { RootState } from "@src/redux/store"

const initialState: UserState = {
  authStateKnown: false,
  isAnonymous: true,
}

export const logOut = createAsyncThunk(
  "user/logOut",
  async (): Promise<void> => {
    signOut(getAuth())
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logOut.fulfilled, () => ({
      ...initialState,
      authStateKnown: true,
    }))
  },
  reducers: {
    setUser: (_, action: PayloadAction<UserState>) => ({
      ...action.payload,
    }),
  },
})

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState) => state.user
export const { setUser } = userSlice.actions

export default userSlice.reducer
