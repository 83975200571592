import React from "react"
import Script from "next/script"
import { Provider as ReduxStoreProvider } from "react-redux"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"

import useCj from "@src/hooks/use-cj"

import GlobalLayoutWrapper from "@src/layouts/global"

import { store } from "@src/redux/store"

import type { AppProps } from "next/app"

import "@src/styles/global.scss"

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE ?? "development",
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React)

export default function OnyxAndRoseApp({ Component, pageProps }: AppProps) {
  useCj()

  return (
    <ErrorBoundary>
      <ReduxStoreProvider store={store}>
        <GlobalLayoutWrapper>
          <Component {...pageProps} />
        </GlobalLayoutWrapper>
        <Script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
          }}
          strategy="afterInteractive"
        />

        <noscript>
          <iframe
            height="0"
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`}
            style={{ display: "none", visibility: "hidden" }}
            width="0"
          />
        </noscript>
      </ReduxStoreProvider>
    </ErrorBoundary>
  )
}
