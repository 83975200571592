import { useEffect, useState } from "react"
import { getApps } from "firebase/app"
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  onIdTokenChanged,
  signInAnonymously,
  type User,
} from "firebase/auth"
import Bugsnag from "@bugsnag/js"
import _forEach from "lodash/forEach"

import { useAppDispatch } from "@src/hooks/use-redux"
import useGtm from "@src/hooks/use-gtm"

import { setCartCount } from "@src/redux/cart.slice"
import { setUser } from "@src/redux/user.slice"

import { GET_INITIAL_STORE } from "@src/operations/api/set-up-store"

import apolloClient, {
  addIdTokenToApolloClient,
} from "@src/utilities/apollo-client"

import type { SetUpStoreQuery } from "@src/entities/types/api"

export default function useSetupUser() {
  const [attachListeners, setAttachListeners] = useState<boolean>(false)
  const [firebaseUser, setFirebaseUser] = useState<User | null | undefined>()
  const dispatch = useAppDispatch()

  async function getInitialStore() {
    const token = await firebaseUser?.getIdToken()
    return apolloClient
      .query<SetUpStoreQuery>({
        context: {
          headers: {
            "firebase-id-token": token,
          },
        },
        query: GET_INITIAL_STORE,
      })
      .then(({ data, errors }) => {
        if (errors) {
          _forEach(errors, (error) => {
            Bugsnag.notify(error)
          })
          return null
        }
        return data
      })
      .catch((error) => {
        Bugsnag.notify(error)
        return null
      })
  }

  useEffect(() => {
    if (typeof firebaseUser === "undefined" || firebaseUser === null) {
      return
    }
    getInitialStore().then((storeData) => {
      dispatch(setCartCount(storeData?.cartCount ?? 0))
      if (storeData) {
        dispatch(
          setUser({
            authStateKnown: true,
            isAnonymous: firebaseUser.isAnonymous,
            ...(firebaseUser?.email && { email: firebaseUser.email }),
            ...(firebaseUser?.uid && { uid: firebaseUser.uid }),
            ...(storeData.me?.firstName && {
              firstName: storeData.me.firstName,
            }),
            ...(storeData.me?.lastName && { lastName: storeData.me.lastName }),
            ...(storeData.me?.phoneNumber && {
              phoneNumber: storeData.me.phoneNumber,
            }),
            ...(storeData.me?.birthDate && {
              birthDate: storeData.me.birthDate,
            }),
            ...(storeData.me?.defaultAddress?.streetOne && {
              streetOne: storeData.me.defaultAddress.streetOne,
            }),
            ...(storeData.me?.defaultAddress?.streetTwo && {
              streetTwo: storeData.me.defaultAddress.streetTwo,
            }),
            ...(storeData.me?.defaultAddress?.city && {
              city: storeData.me.defaultAddress.city,
            }),
            ...(storeData.me?.defaultAddress?.state && {
              state: storeData.me.defaultAddress.state,
            }),
            ...(storeData.me?.defaultAddress?.zip && {
              zip: storeData.me.defaultAddress.zip,
            }),
            ...(storeData.me?.defaultAddress?.country && {
              country: storeData.me.defaultAddress.country,
            }),
          })
        )
        useGtm({
          event: "orIdentify",
          traits: {
            ...(firebaseUser.metadata.creationTime && {
              createdAt: new Date(
                firebaseUser.metadata.creationTime
              ).toISOString(),
            }),
            ...(firebaseUser?.email && { email: firebaseUser.email }),
            ...(firebaseUser?.isAnonymous && {
              isGuest: firebaseUser.isAnonymous,
            }),
            ...(firebaseUser?.uid && { uid: firebaseUser.uid }),
            ...(storeData.me?.firstName && {
              firstName: storeData.me.firstName,
            }),
            ...(storeData.me?.lastName && {
              lastName: storeData.me.lastName,
            }),
            ...(storeData.me?.phoneNumber && {
              phone: storeData.me.phoneNumber,
            }),
            ...(storeData.me?.birthDate && {
              birthday: new Date(storeData.me.birthDate).toISOString(),
            }),
            ...(storeData.me?.defaultAddress && {
              address: {
                ...(storeData.me?.defaultAddress?.streetOne && {
                  street: storeData.me.defaultAddress.streetOne,
                }),
                ...(storeData.me?.defaultAddress?.streetTwo && {
                  streetTwo: storeData.me.defaultAddress.streetTwo,
                }),
                ...(storeData.me?.defaultAddress?.city && {
                  city: storeData.me.defaultAddress.city,
                }),
                ...(storeData.me?.defaultAddress?.state && {
                  state: storeData.me.defaultAddress.state,
                }),
                ...(storeData.me?.defaultAddress?.zip && {
                  zip: storeData.me.defaultAddress.zip,
                }),
                country: storeData.me?.defaultAddress.country ?? "US",
              },
            }),
          },
          uid: firebaseUser.uid,
        })
      }
    })
  }, [firebaseUser])

  useEffect(() => {
    if (!attachListeners) {
      return () => {}
    }
    const auth = getAuth()
    const authListener = onAuthStateChanged(auth, async (firUser) => {
      if (firUser === null) {
        signInAnonymously(auth)
      } else {
        setFirebaseUser(firUser)
      }
      const idToken = await firUser?.getIdToken()
      addIdTokenToApolloClient(idToken)
    })
    const idTokenListener = onIdTokenChanged(auth, async (firUser) => {
      const idToken = await firUser?.getIdToken()
      addIdTokenToApolloClient(idToken)
    })
    return () => {
      authListener()
      idTokenListener()
    }
  }, [attachListeners])

  useEffect(() => {
    if (
      getApps().length === 1 &&
      !isSignInWithEmailLink(getAuth(), window.location.href)
    ) {
      setAttachListeners(true)
    }
  }, [getApps().length])
}
