import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import type { CartState } from "@src/entities/types/state"

import type { RootState } from "@src/redux/store"

const initialState: CartState = {
  count: -1,
  discountCodeErrorMessage: null,
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearDiscountErrorMessage: (state) => ({
      ...state,
      discountCodeErrorMessage: null,
    }),
    setDiscountCodeErrorMessage: (state, action: PayloadAction<string>) => ({
      ...state,
      discountCodeErrorMessage: action.payload,
    }),
    setCartCount: (state, action: PayloadAction<number>) => ({
      ...state,
      count: action.payload,
    }),
  },
})

// Other code such as selectors can use the imported `RootState` type
export const getCartCount = (state: RootState) => state.cart.count
export const getDiscountCodeError = (state: RootState) =>
  state.cart.discountCodeErrorMessage
export const {
  clearDiscountErrorMessage,
  setCartCount,
  setDiscountCodeErrorMessage,
} = cartSlice.actions

export default cartSlice.reducer
