import { configureStore } from "@reduxjs/toolkit"

import cartReducer from "@src/redux/cart.slice"
import userReducer from "@src/redux/user.slice"

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AsyncThunkConfig<T = any> = {
  rejectValue: T
  state: RootState
}

export type AppDispatch = typeof store.dispatch
