import React from "react"
import { ApolloProvider } from "@apollo/client"

import useSetupStore from "@src/hooks/use-setup-store"

import apolloClient from "@src/utilities/apollo-client"
import FirebaseWrapper from "@src/utilities/firebase-wrapper"

export interface GlobalLayoutWrapperProps {
  children?: React.ReactNode
}

export default function GlobalLayoutWrapper({
  children,
}: GlobalLayoutWrapperProps) {
  useSetupStore()

  return (
    <FirebaseWrapper>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </FirebaseWrapper>
  )
}
