import type { UtmMap } from "@src/entities/models/utm"

function getFromStorage(name: string): UtmMap | null {
  const str = window.sessionStorage.getItem(name)
  if (!str) {
    return null
  }
  return JSON.parse(str) as UtmMap
}

function getFromUrl(url: string): Partial<UtmMap> | null {
  const response: Partial<UtmMap> = {}
  const params = new URLSearchParams(url)
  params.forEach((val, key) => {
    switch (key) {
      case "utm_campaign":
      case "utm_content":
      case "utm_medium":
      case "utm_source":
      case "utm_term":
        response[key] = val
        break
      default:
    }
  })
  if (Object.keys(response).length === 0) {
    return null
  }
  return response
}

export default function useUtm(): Partial<UtmMap> | null {
  const storageKey = "utmMap"
  if (typeof window === "undefined") {
    return null
  }
  const mapFromUrl = getFromUrl(window.location.search)
  if (mapFromUrl) {
    return mapFromUrl
  }
  return getFromStorage(storageKey)
}
