import { useEffect } from "react"
import { parseCookies, setCookie } from "nookies"
import _map from "lodash/map"
import _reduce from "lodash/reduce"

import type { GetOrderCompleteQuery } from "@src/entities/types/api"

declare global {
  interface Window {
    cj?: {
      order: {
        actionTrackerId: string
        amount: number
        cjeventOrder: string
        coupon?: string
        currency: "USD"
        discount: number
        enterpriseId: string
        items: {
          discount: number
          itemId: string
          quantity: number
          unitPrice: number
        }[]
        orderId: string
      }
    }
  }
}

export default function useCj(
  order?: GetOrderCompleteQuery["order"]["order"] | null
) {
  useEffect(() => {
    const cookies = parseCookies()
    if (!order || !cookies.cje) {
      return
    }
    if (!window.cj?.order) {
      const lineItemsPricePaidTotals = _reduce(
        order.lineItems,
        (sum, item) => sum + item.pricePaid * item.quantity,
        0
      )
      const orderSubtotal =
        lineItemsPricePaidTotals - (order?.totals.discounts ?? 0)
      window.cj = {
        order: {
          actionTrackerId: "424756",
          amount: Number((0.01 * orderSubtotal).toFixed(2)),
          cjeventOrder: cookies.cje,
          ...(order.discount?.code && { coupon: order.discount.code }),
          currency: "USD",
          discount:
            Number((0.01 * order?.totals.discounts ?? 0).toFixed(2)) ?? 0,
          enterpriseId: "1562759",
          items: _map(order.lineItems, (lineItem) => ({
            discount: 0,
            itemId: lineItem.product?.sku ?? "",
            quantity: lineItem.quantity,
            unitPrice: Number((0.01 * lineItem.pricePaid).toFixed(2)),
          })),
          orderId: order.id,
        },
      }
    }
  }, [order])

  useEffect(() => {
    if (!order) {
      // If this isn't a conversion page, check to see if the url
      // has a referral event from CJ and, if so, set a cookie
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const cjevent = urlParams.get("cjevent")
      if (cjevent) {
        setCookie(null, "cje", cjevent, {
          ...(process.env.NEXT_PUBLIC_DOMAIN_NAME && {
            domain: process.env.NEXT_PUBLIC_DOMAIN_NAME,
          }),
          maxAge: 13 * 30 * 24 * 60 * 60,
          path: "/",
          sameSite: "lax",
          secure: process.env.NEXT_PUBLIC_WEBSITE_BASE_URL?.includes("https"),
        })
      }
    }
  }, [order])
}
