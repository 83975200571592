export default function useGtm({
  event,
  eventName,
  properties,
  traits,
  uid,
}: DataLayerEvent) {
  if (typeof window === "undefined") {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event,
    ...(eventName && { eventName }),
    ...(properties && { properties }),
    ...(traits && { traits }),
    ...(uid && { uid }),
  })
}
