import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import useUtm from "@src/hooks/use-utm"

export const apiLink = new HttpLink({
  credentials: "include",
  // useGETForQueries: true,
  uri: `${process.env.NEXT_PUBLIC_API_BASE_URL}/graphql`,
})

export const storyblokLink = new HttpLink({
  uri: `https://gapi.storyblok.com/v1/api`,
})

const client = new ApolloClient({
  cache: new InMemoryCache({
    resultCaching: false,
  }),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    },
    query: {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    },
    mutate: {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    },
  },
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "storyblok",
    storyblokLink,
    apiLink
  ),
  ssrMode: true,
})

export function addIdTokenToApolloClient(idToken: string | undefined) {
  if (!idToken) {
    return
  }
  const utms = useUtm()
  const idTokenLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      "clean-site": true,
      "firebase-id-token": idToken || null,
      ...(utms && { utms: JSON.stringify(utms) }),
    },
  }))
  client.setLink(
    ApolloLink.split(
      (operation) => operation.getContext().clientName === "storyblok",
      storyblokLink,
      idTokenLink.concat(apiLink)
    )
  )
}

export default client
